import {
	AuthenticationState,
	actionTypes as actionTypesOfAuth,
} from '@/modules/authentication/store/types'
import store from '@/store/store'
import isTrialExpired from '@/utils/functions/isTrialExpired'
import { NavigationGuardNext, Route } from 'vue-router'

export const routeMiddleware = async (
	to: Route,
	from: Route,
	next: NavigationGuardNext<Vue>
): Promise<void> => {
	const authentication: AuthenticationState = store.state.Auth

	const { isLoggedIn } = authentication

	if (isLoggedIn) {
		if (!to.meta?.register) {
			if (store.state.Organizacao.organizacao) {
				const trialDateExpires = store.state.Organizacao.organizacao.trial_date_expires

				if (
					trialDateExpires &&
					isTrialExpired(trialDateExpires) &&
					to.name !== 'plans.active'
				) {
					next({ name: 'plans.active' })
					return
				}

				next()
				return
			} else if (store.state.Organizacao.organizacoes.length) {
				const isPermission = await store.dispatch(
					`Auth/${actionTypesOfAuth.SET_PERMISSIONS_USER}`,
					to
				)

				if (!isPermission) {
					next({
						name: 'forbidden.index',
						query: { redirect: to.fullPath },
					})
					return
				}
			}

			next()
			return
		} else if (to.name !== 'painel') {
			next({ name: 'painel', query: to.query })
			return
		} else {
			next()
			return
		}
	} else {
		if (to.meta?.auth === true) {
			if (to.name !== 'auth') next({ name: 'auth', query: to.query })
			next()
			return
		} else {
			next()
			return
		}
	}
}
