import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Sentry from '@sentry/vue'

// Modules routers
import authRoutes from './modules/autenticacao/router'
import dashboardRoutes from './modules/dashboard/router'
import customerRoutes from './modules/customers/router'
import perfilRoutes from './modules/perfil/router'
import rotuloRoutes from './modules/rotulos/router'
import requestRoutes from './modules/requisicoes/router'
import pageNotFoundRoutes from './modules/notfound/router'
import configRoutes from './config/router'
import animalRoutes from './modules/animals/router'
import forbiddenRoutes from './modules/forbidden/router'
import receivingRoutes from './modules/receiving/router'
import {
	insertResultRoutes,
	previewResultRoutes,
	resultRoutes,
	insertResultExamGroupRoutes,
} from './modules/insertionResult/router'
import orderRoutes from './modules/orders/router'
import planRoutes from './modules/plans/router'
import { routeMiddleware } from './middleware/routeMiddleware'
// ----------------------------------

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: () => import('./components/layouts/dashboard/DashboardLayout.vue'),
		children: [
			...dashboardRoutes,
			...customerRoutes,
			...perfilRoutes,
			...rotuloRoutes,
			...requestRoutes,
			...animalRoutes,
			...receivingRoutes,
			...insertResultRoutes,
			...insertResultExamGroupRoutes,
			...orderRoutes,
		],
	},
	{
		path: '/config',
		component: () => import('./components/layouts/configurations/ConfigurationsLayout.vue'),
		children: configRoutes,
	},
	...resultRoutes,
	...previewResultRoutes,
	...authRoutes,
	...forbiddenRoutes,
	...pageNotFoundRoutes,
	...planRoutes,
]

const router = new VueRouter({
	mode: 'history',
	base: '/',
	linkExactActiveClass: 'active',
	routes,
})

router.beforeEach(routeMiddleware)

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		Vue,
		dsn: 'https://4de6badfcbd4036c98df3f69505e778f@o4505907089178624.ingest.sentry.io/4505907097436160',
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: 'https://app.clustervet.com',
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
			// new Sentry.Replay({
			// 	maskAllText: false,
			// 	blockAllMedia: false,
			// 	maskAllInputs: false,
			// }),
		],
		// Performance Monitoring
		tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		// replaysOnErrorSampleRate: 0.8, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		environment: process.env.NODE_ENV,
	})
}

export default router
